import React from 'react'
import { graphql } from 'gatsby'
import { Block, Banner, Breadcrumbs } from '@components'
import ModuleArea from '@helpers/moduleArea'
import Layout from '@layouts/layout'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Page = ({ data, pageContext, location }) => {
  const {
    banner,
    mobileBanner,
    bannerHeadingNode,
    moduleArea,
    seoMetaTags
  } = data.page

  const imageSources = [
    mobileBanner?.fluid,
    {
      ...banner?.fluid,
      media: `(min-width: 700px)`
    }
  ]

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      {banner && (
        <Banner
          banner={imageSources}
          heading={bannerHeadingNode?.childMarkdownRemark?.html}
        />
      )}
      {pageContext?.breadcrumbs && (
        <Block gutters>
          <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} />
        </Block>
      )}
      <Block>
        <ModuleArea
          modules={moduleArea}
          allPosts={data.allPosts.nodes}
          location={location}
        />
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query pageById($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      id
      path
      slug
      title
      bannerHeadingNode {
        childMarkdownRemark {
          html
        }
      }
      banner {
        fluid(
          maxWidth: 1660
          imgixParams: { w: "1660", h: "600", fit: "crop" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      mobileBanner: banner {
        fluid(maxWidth: 700, imgixParams: { w: "700", h: "800", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      ...PageContentModuleArea
    }

    allPosts: allDatoCmsPost(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        ...PostCard
      }
    }
  }
`
export default Page
